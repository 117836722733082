import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/details-layout"
import Seo from "../../components/seo"
import * as styles from "../../less/styles.scss"
import "./gift-shop.scss"

import Gift from "./components/gift-shop"

const GiftShop = () => (
  <Layout>
    <Seo title="D-Golden Tours| D-Golden Tours Gift Shop " />
    <Gift/>
  </Layout>
)

export default GiftShop;
